import { useLoaderData, useNavigate } from '@remix-run/react';

import { type FAQGroup, FAQGroupUtils } from '../components/FAQ';
import { useCloseRoutedGamePackEditor } from '../components/Game/GamePack';
import { GamePackV2Editor } from '../components/Game/GamePack/GamePackV2Editor';
import config from '../config';
import { useLiveCallback } from '../hooks/useLiveCallback';
import { apiService } from '../services/api-service';
import { type GamePack } from '../types/game';

export async function clientLoader() {
  const faqGroupIds = config.misc.faqGroupIds;
  const anonymousFaqGroupIds = config.misc.anonymousFaqGroupIds;

  let faqGroups: FAQGroup[] = [];
  let anonymousFAQGroups: FAQGroup[] = [];
  const allFaqGroupIds = [...faqGroupIds, ...anonymousFaqGroupIds];
  if (allFaqGroupIds.length > 0) {
    const sharedAssets = await apiService.media
      .querySharedAsset({
        type: 'by-ids',
        ids: allFaqGroupIds.join(','),
      })
      .next();
    const allFAQGroups = sharedAssets.map((m) =>
      FAQGroupUtils.FromSharedAsset(m)
    );
    faqGroups = allFAQGroups.filter((g) => faqGroupIds.includes(g.id));
    anonymousFAQGroups = allFAQGroups.filter((g) =>
      anonymousFaqGroupIds.includes(g.id)
    );
  }

  return {
    faqGroups,
    anonymousFAQGroups,
  };
}

export const Component = () => {
  const { faqGroups, anonymousFAQGroups } =
    useLoaderData<typeof clientLoader>();

  const closeEditor = useCloseRoutedGamePackEditor();
  const navigate = useNavigate();
  const handleSubmit = useLiveCallback((gamePack: GamePack) => {
    navigate(`/admin/gamepacks/edit/${gamePack.id}`);
  });

  return (
    <GamePackV2Editor
      faqGroups={faqGroups}
      anonymousFAQGroups={anonymousFAQGroups}
      onClose={closeEditor}
      onSubmit={handleSubmit}
    />
  );
};
